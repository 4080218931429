.nav-bar {
    background: #393E46;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;
  
    .logo {
      display: block;
      padding: 8px 7px;
  
      img {
        display: block;
        margin: 8px auto;
        width: 85%;
        height: 100%;
  
        &.sub-logo {
            width: 50px;
        }
      }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

        a {
            font-size: 22px;
            color: #4d4d4e;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;

            i {
                transition: all 0.3 ease-out;
            }

            &:hover {
                color: #00ADB5;

                svg {
                    opacity:0;
                }
                &:after {
                    opacity: 1;
                }
            }

            &:after{
               content: '';
               font-size: 9px;
               position:absolute;
               bottom: 0;
               display: block;
               width: 100%;
               text-align: center;
               opacity: 0;
               transition: all 0.3s ease-out; 
            }
            &.home-link{
                &::after{
                    content: 'HOME';
                    margin-top: 5px;
                }
            }
        }
        a.about-link {
            &:after {
                content: 'ABOUT';
                margin-top: 3px;
            }
        }

        a.contact-link {
            &:after {
                content: 'CONTACT';
            }
        }

        a.active {
            svg {
                color: #00ADB5;
            }
        }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0%;

        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 15px;
                line-height: 16px;

                &:hover svg,
                .anchor-icon { 
                    color: #00ADB5;

                }
            }
        }
    }

}

.hamburger-icon, .close-icon {
    display: none;
  }
  

@media screen and (max-width: 1200px) {
    .nav-bar { 
      background: transparent;
      position: initial;
      height: auto;
      min-height: auto;
  
       ul, nav {
        display: none;
       }
  
       nav {
        width: 100%;
        top: 0;
        left: 0;
        background: #181818;
        height: 100%;
        margin: 0;
        z-index: 2;
        position: fixed;
        
        &.mobile-show {
          display: block;
        }
  
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 15%;
        }
  
        a:after {
          opacity: 1;
          position: initial;
          width: auto;
          margin-left: 10PX;
          color: #fff;
          font-size: 2REM;
          margin-right: 4rem;
        }

        a.active::after {
           color: #00ADB5;
        }
  
        a svg {
          opacity: 1 !important;
        }
       }
  
       .hamburger-icon {
          position: absolute;
          top: 3%;
          right: 3.5%;
          display: block;
       }

       .close-icon{
        display: block;
        width: 100%;
        height: 70%;
        margin-top: 2.5%;
        margin-left: 45%;
       }
       .spaceBreak {
        background: transparent;
        position: initial;
        height: 60px;
        width: 100%;
       }
    }
  }


