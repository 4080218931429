.logo-container {
    z-index: 0;
    width: 400px;
    height: 609px;
    position: absolute;
    top: 0px;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
    opacity: 1;


    .solid-logo {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        opacity: 1;
        z-index: 1;
    }
}

@media screen and (max-width: 800px) {

    .logo-container {
        width: 50%;
        height: 606px;
        position: relative;
        right: 0;
    }

    .logo-container .solid-logo {
        width: 100%;
    }
}